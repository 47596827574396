.navbar {
    font-family: 'Libre Baskerville', serif;
    -webkit-transition: background-color 500ms linear;
    -ms-transition: background-color 500ms linear;
    transition: background-color 500ms linear;
}

.navbar-brand {
    padding: 0 5px;
    border: 2px solid white;
}

.item-click {
    cursor: pointer;
}

.active {
    padding-bottom: 5%;
    border-bottom: 2px white solid;
}
@media only screen and (max-width: 991px) {
    .nav-item {
        text-align: right;
    }
    .active {
        padding-bottom: 1%;
    }
}