.services {
    text-align: center;
}
.services-format {
    padding: 0 100px 60px 100px;
    text-align: center;
    margin: 0;
}
.services-sub {
    color: rgb(160, 24, 51);
    text-align: center;
}
.services-disclaimer {
    text-align: center;
}
.table-menu {
    width: 100%;
}
.menu-item-description {
    color: #505050;
}
.border{
    padding: 80px 50px;
    border: 1px solid lightgrey;
}
.divider {
    display: none;
}
td.menu-price {
    vertical-align: top;
}
.menu-section {
    padding-bottom: 50px;
}
.menu-bot {
    padding-bottom: 40px;
}
.small-menu, .smaller-menu {
    width: 100%;
    margin: 0 auto;
}
.smaller-menu {
    padding-left: 50px;
    padding-right: 50px;
}
@media only screen and (max-width: 991px) {
    .column-block {
        display: block;
        width: 100%;
    }
    .menu-title {
        display: block;
    }
    td.menu-price {
        padding-bottom: 12px;
    }
    .table-menu {
        text-align: center;
    }
    .services-sub {
        padding-bottom: 10px;
    }
}
@media (min-width: 576px) {
    .small-menu {
        max-width: 400px;
    }
}
@media only screen and (min-width: 768px) {
    .small-menu {
        max-width: 600px;
    }
}
@media only screen and (min-width: 992px) {
    .divider {
        display: inline;
    }
    .menu-price {
        text-align: right;
    }
    .menu-price.small-menu-text-left {
        text-align: left;
        width: 200px;
    }
    tr {
        line-height: 50px;
    }
    tr td div {
        max-width: 600px;
    }
    .services-sub {
        text-align: left;
    }
    .small-menu {
        max-width: 750px;
    }
}
@media only screen and (min-width: 1200px) {
    tr td div {
        max-width: 800px
    }
    .small-menu {
        max-width: 950px;
    }
    .smaller-menu {
        max-width: 600px;
    }
}