.navbar {
    font-family: 'Libre Baskerville', serif;
    transition: background-color 500ms linear;
}

.navbar-brand {
    padding: 0 5px;
    border: 2px solid white;
}

.item-click {
    cursor: pointer;
}

.active {
    padding-bottom: 5%;
    border-bottom: 2px white solid;
}
@media only screen and (max-width: 991px) {
    .nav-item {
        text-align: right;
    }
    .active {
        padding-bottom: 1%;
    }
}

.home {
    font-family: 'Libre Baskerville', serif;
}
.display-1 {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 700;
    font-size: 8rem;
}
.hero {
    width: 100vw;
    height: 100vh;

    color: white;

    /* flexbox */
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    text-align: center;

    /* Background styles */
    background-image: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)), url(/static/media/flower.de24c193.jpeg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;


    /* transition: border-color 240ms ease-in-out;
    transition-property: border-color;
    transition-duration: 240ms;
    transition-timing-function: ease-in-out;
    transition-delay: 0s; */
}
.call-to-action {
    color: white;
    background-color: #21ce99;
    
    /* Border styles */
    /* border: 3px solid white; */
}
.btn-rounded {
    padding: 1em;
    border-radius: 50px;
}

.call-to-action:hover {
    color: white;
    background-color: #01ae79;
}

.message {
    padding: 200px 0;
    margin: 0 auto;
    text-align: center;
}
div {
    overflow: hidden;
}

.container-fluid {
    padding: 0;
}

.box {
    width: 80%;
    color: white;
    /* float: left; */
}

.box-right {
    width: 80%;
    color: white;
    /* float: right; */
}

.spacing {
    margin-bottom: 200px;
}
.box-padding {
    padding: 80px 50px;
}
.qs {
    background-color: #ff909c;
}
.hs {
    background-color: #3fa7d6;
}
.sc {
    background-color: #a4a1ec;
}

.heading-about {
    text-align: left;
}
/* .about-message {
border: 0; 
  animation: fadein 2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadein {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}  */

.about-subtitle {
    margin-bottom: 15px;
}
hr { 
    display: block;
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  border-style: inset;
  border-width: 1px;
}

p {
    margin-bottom: 0;
}

p a {
    color: white;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}
p a:hover {
    color: plum;
}
@media(min-width: 992px) {
    hr {
        display: none;
    }
}

@media(min-width: 1200px) {
    .box-about {
        /* padding: 50px 100px; */
    }

    .heading-about {
        margin-left: 15%;
    }
    .box-padding {
        padding: 80px 120px;
    }
    .qs {
        text-align: end;
    }
    .sc {
        text-align: end;
    }
}
.services {
    text-align: center;
}
.services-format {
    padding: 0 100px 60px 100px;
    text-align: center;
    margin: 0;
}
.services-sub {
    color: rgb(160, 24, 51);
    text-align: center;
}
.services-disclaimer {
    text-align: center;
}
.table-menu {
    width: 100%;
}
.menu-item-description {
    color: #505050;
}
.border{
    padding: 80px 50px;
    border: 1px solid lightgrey;
}
.divider {
    display: none;
}
td.menu-price {
    vertical-align: top;
}
.menu-section {
    padding-bottom: 50px;
}
.menu-bot {
    padding-bottom: 40px;
}
.small-menu, .smaller-menu {
    width: 100%;
    margin: 0 auto;
}
.smaller-menu {
    padding-left: 50px;
    padding-right: 50px;
}
@media only screen and (max-width: 991px) {
    .column-block {
        display: block;
        width: 100%;
    }
    .menu-title {
        display: block;
    }
    td.menu-price {
        padding-bottom: 12px;
    }
    .table-menu {
        text-align: center;
    }
    .services-sub {
        padding-bottom: 10px;
    }
}
@media (min-width: 576px) {
    .small-menu {
        max-width: 400px;
    }
}
@media only screen and (min-width: 768px) {
    .small-menu {
        max-width: 600px;
    }
}
@media only screen and (min-width: 992px) {
    .divider {
        display: inline;
    }
    .menu-price {
        text-align: right;
    }
    .menu-price.small-menu-text-left {
        text-align: left;
        width: 200px;
    }
    tr {
        line-height: 50px;
    }
    tr td div {
        max-width: 600px;
    }
    .services-sub {
        text-align: left;
    }
    .small-menu {
        max-width: 750px;
    }
}
@media only screen and (min-width: 1200px) {
    tr td div {
        max-width: 800px
    }
    .small-menu {
        max-width: 950px;
    }
    .smaller-menu {
        max-width: 600px;
    }
}
#map {
    width: 100%;
    height: 300px;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 300px;
    background-color: grey;
    display: -webkit-flex;
    display: flex;
}
.table-hours {
    width: 100%;
    text-align: center;
}
.address, .contact-info, .hours-of-op {
    margin: 20px;
}
.sameline {
    white-space: nowrap;
}
.padding-auto {
    padding: auto;
}
.min-width {
    min-width: 80px;
}
.text-left-align {
    text-align: left;
}
.align-center {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.horizontal-center {
    margin: 0 auto;
}
.margin-bottom-md {
    margin-bottom: 3rem;
}
.contact {
    width: 100vw;
    height: 30vh;

    color: white;

    /* flexbox */
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    text-align: center;

    /* Background styles */
    background-image: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.5)), url(/static/media/storefront.e3d4d0d9.jpeg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;


    /* transition: border-color 240ms ease-in-out;
    transition-property: border-color;
    transition-duration: 240ms;
    transition-timing-function: ease-in-out;
    transition-delay: 0s; */
}
.google-maps-iframe {
    width: 100%;
    height: 100%;
    border: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    margin: 0;
    padding: 0;
}
@media only screen and (min-width: 576px) {
    .address, .contact-info, .hours-of-op {
        margin: 0;
    }
    .address, .contact-info {
        padding-left: 10px;
        text-align: left;
    }
    .enough-space {
        padding: 0;
    }
    .enough-space.left {
        padding-right: 10px;
    }
    .enough-space.right {
        padding-left: 10px;
    }
    .table-hours {
        width: auto;
    }
    .hours {
        margin-top: 0;
    }
}
@media only screen and (min-width: 768px) {
    .address, .contact-info, .hours-of-op {
        margin: 20px;
    }
    .address, .contact-info {
        padding-left: 0px;
        text-align: center;
    }
    .enough-space {
        padding-left: 15px;
        padding-right: 15px;
    }
    #map {
        display: block;
    }
}
@media only screen and (min-width: 992px) {
    .hours-of-op {
        padding-left: 100px;
    }
}
